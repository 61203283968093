<template>
  <TemplateSettingComp />
</template>

<script>
import TemplateSettingComp from "@/layouts/TemplateSettingComp.vue";

export default {
  name: "TemplateSetting",
  components: { TemplateSettingComp },
};
</script>
